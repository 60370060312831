<template>
  <div class="content-global content-tables-OC">
    <div class="global-content-table">
      <filter-component-global v-if="$route.name !== 'OPPayable'" class="global-filters" @setDateRange="setDateRange"
        @setStatusOC="setStatusOC" @setSearch="setSearch" :labelName="sLabelNameFilter"
        :placeholderName="sPlaceholderName" />
      <no-data-text-component-global :sTextEmpty="sTextEmpty" :iHeight="screenHeight" v-if="bEmptyTable" />
      <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="aHeaders" :disable-sort="true"
        :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..." :items-per-page="iItemsPerPage"
        :hide-default-footer="true" no-data-text="No hay información disponible para mostrar." :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }" class="global-table">
        <template slot="progress">
          <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
        </template>
        <template v-slot:[`item.sDriverLicensePlate`]="{ item }">
          <span v-if="
            item.sDriverLicensePlate !== 'N/A' ||
            item.sDriverLicensePlate !== ''
          ">
            {{ item.sDriverLicensePlate }}
          </span>
          <span v-else class="text-data-empty">
            {{ sEmptyData }}
          </span>
        </template>
        <template v-slot:[`item.dTotalAmount`]="{ item }">
          ${{ formatMoneyGlobal(item.dTotalAmount ? item.dTotalAmount : 0) }}
        </template>
        <template v-slot:[`item.accions`]="{ item }">
          <v-btn @click="detailOC(item)" class="global-btn-table-detail" icon>
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <footer-table-component-global :numPages="numPages" :totalItems="totalItems" @changeTable="getEmitOC" />
        </template>
      </v-data-table>
      <table-mobile-o-c-component-global v-show="isMobile" @changeTable="getEmitOC" @openDetailItem="detailOC"
        :aTable="aTable" :headers="aHeaders" :numPages="numPages" :totalItems="totalItems"
        :bChangeSelectedItem="bChangeSelectedItem" />
    </div>
  </div>
  <!-- <footer-component-global /> -->
</template>

<script>
export default {
  props: {
    aHeaders: Array,
    sApiGetOC: String,
    sTypeItem: Number,
    sTextTitle: String,
    sLabelNameFilter: String,
    sPlaceholderName: String,
    bChangeSelectedItem: Boolean,
  },
  data() {
    return {
      screenHeight: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      aTable: [],
      isMobile: false,
      aDateRange: [],
      sSearch: "",
      sStartDate: "",
      sEndDate: "",
      sStatusOC: "",
      sEmptyData: "Sin información.",
      sTextEmpty: "<p>No existen operaciones registradas para mostrar</p>",

    };
  },
  beforeMount() {
    this.getOC();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
        this.screenHeight = window.innerHeight - 260;
      } else {
        this.isMobile = true;
        this.screenHeight = window.innerHeight - 395;
      }

      if (window.innerWidth > 960) {
        this.screenWidth = window.innerWidth - 900;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    detailOC(item) {
      let sNameRoute = "";
      switch (this.sTypeItem) {
        case 1:
          sNameRoute = "OPGeneralDetail";
          break;
        case 2:
          sNameRoute = "OPPendingDetail";

          break;
        case 3:
          sNameRoute = "OPPayableDetail";
          break;

        default:
          break;
      }
      this.$router
        .push({
          name: sNameRoute,
          params: { idOC: item.sPurchaseOrderId },
        })
        .catch((e) => { });
    },
    getEmitOC(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getOC();
    },
    getOC() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const params = this.getParams();
          DB.get(this.sApiGetOC, {
            params: params,
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;

              this.aTable = response.data.results.map((e) => {
                return {
                  ...e,
                  sNameVendor: e.oVendor.sName,
                  sStatusName: e.oPurchaseOrderStatus.sName,
                };
              });

              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {

              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getParams() {
      switch (this.$route.name) {
        case "OPGeneral":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            sStatusId: this.sStatusOC,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };
        case "OPPending":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            sStatusId: this.sStatusOC,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };
        case "OPPayable":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            sStatusId: this.sStatusOC,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };

        default:
          break;
      }
    },

    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setDateRange(oDateRange) {
      this.sStartDate = oDateRange.sStartDate;
      this.sEndDate = oDateRange.sEndDate;
      this.getOC();
    },
    setStatusOC(sStatusOC) {
      this.sStatusOC = sStatusOC;
      this.getOC();
    },

    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getOC();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable() {
      this.getOC();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getOC();
    },
    iCurrentPage() {
      this.getOC();
    },
    bChangeSelectedItem() {
      this.getOC();
    },
  },
};
</script>

<style >
.content-OPGlobal {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}
</style>