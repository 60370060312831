var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-global content-tables-OC"},[_c('div',{staticClass:"global-content-table"},[(_vm.$route.name !== 'OPPayable')?_c('filter-component-global',{staticClass:"global-filters",attrs:{"labelName":_vm.sLabelNameFilter,"placeholderName":_vm.sPlaceholderName},on:{"setDateRange":_vm.setDateRange,"setStatusOC":_vm.setStatusOC,"setSearch":_vm.setSearch}}):_vm._e(),(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"sTextEmpty":_vm.sTextEmpty,"iHeight":_vm.screenHeight}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.aHeaders,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"item.sDriverLicensePlate",fn:function(ref){
      var item = ref.item;
return [(
          item.sDriverLicensePlate !== 'N/A' ||
          item.sDriverLicensePlate !== ''
        )?_c('span',[_vm._v(" "+_vm._s(item.sDriverLicensePlate)+" ")]):_c('span',{staticClass:"text-data-empty"},[_vm._v(" "+_vm._s(_vm.sEmptyData)+" ")])]}},{key:"item.dTotalAmount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatMoneyGlobal(item.dTotalAmount ? item.dTotalAmount : 0))+" ")]}},{key:"item.accions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailOC(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitOC}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-o-c-component-global',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.aHeaders,"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.getEmitOC,"openDetailItem":_vm.detailOC}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }